.todo-filter {
  text-align: right;
}

.todo-filter-list {
  margin: 0 0 2em 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.todo-filter-item:first-child .todo-filter-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.todo-filter-item:last-child .todo-filter-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.todo-filter-item:not(:first-child):not(:last-child) .todo-filter-btn {
  border-radius: 0;
}

.todo-filter .todo-filter-btn.is-active,
.todo-filter .todo-filter-btn.is-active:hover,
.todo-filter .todo-filter-btn.is-active:focus,
.todo-filter .todo-filter-btn.is-active:active {
  pointer-events: none;
  background-color: #bbb;
}
