.todo-item--completed {
  background-color: #e6e6e6;
}

.todo-item-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.todo-item-box-label {
  text-transform: none;
  font-size: 0.875rem;
}

.todo-task,
.todo-project {
  word-break: break-all;
  overflow-wrap: break-word;
}

.todo-priority {
  font-size: 87.5%;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.todo-priority-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5em;
  text-align: right;
  border-radius: 0.25em;
  font-weight: 600;
  background-color: #f0f0f0;
}

.todo-item--completed .todo-priority-box {
  background-color: #fff;
}

.todo-priority-icon {
  display: inline-block;
  vertical-align: middle;
  margin-inline-start: 1em;
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
}

.todo-priority-icon--low {
  background-color: #f4c000;
}

.todo-priority-icon--normal {
  background-color: #41a452;
}

.todo-priority-icon--high {
  background-color: #d5223e;
}

.todo-action {
  justify-content: center;
}

.todo-btn {
  width: 100%;
}

.todo-btn-icon {
  color: inherit;
}

@media (min-width: 64em) {
  .todo-item-box {
    display: block;
  }

  .todo-item-box-label {
    display: none;
  }
}
