.TodoForm_todo-form__3J30U {
  margin-bottom: 2em;
}

.TodoForm_todo-form-row__31L-e {
  display: grid;
  align-items: end;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  gap: 1em;
}

.TodoForm_todo-btn__1lbxQ {
  width: 100%;
}

@media (min-width: 64em) {
  .TodoForm_todo-form-row__31L-e {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }
}

.TodoFilter_todo-filter__uFYHA {
  text-align: right;
}

.TodoFilter_todo-filter-list__3evr- {
  margin: 0 0 2em 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.TodoFilter_todo-filter-item__1qSyV:first-child .TodoFilter_todo-filter-btn__2TOpO {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.TodoFilter_todo-filter-item__1qSyV:last-child .TodoFilter_todo-filter-btn__2TOpO {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.TodoFilter_todo-filter-item__1qSyV:not(:first-child):not(:last-child) .TodoFilter_todo-filter-btn__2TOpO {
  border-radius: 0;
}

.TodoFilter_todo-filter__uFYHA .TodoFilter_todo-filter-btn__2TOpO.TodoFilter_is-active__2vjub,
.TodoFilter_todo-filter__uFYHA .TodoFilter_todo-filter-btn__2TOpO.TodoFilter_is-active__2vjub:hover,
.TodoFilter_todo-filter__uFYHA .TodoFilter_todo-filter-btn__2TOpO.TodoFilter_is-active__2vjub:focus,
.TodoFilter_todo-filter__uFYHA .TodoFilter_todo-filter-btn__2TOpO.TodoFilter_is-active__2vjub:active {
  pointer-events: none;
  background-color: #bbb;
}

.TodoItemCommon_todo-item__1k8Ni {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  background-color: #fff;
  padding: 0.5em 0.5em 0.5em 1em;
  grid-gap: 2em;
  gap: 2em;
  border-radius: 0.25em;
}

.TodoItemCommon_todo-item--header__1a3oi {
  display: none;
  background-color: #dadada;
}

@media (min-width: 64em) {
  .TodoItemCommon_todo-item__1k8Ni,
  .TodoItemCommon_todo-item--header__1a3oi {
    display: grid;
    grid-template-columns: 6em 1fr 20em 7em 5em;
  }
}

.TodoItem_todo-item--completed__19luh {
  background-color: #e6e6e6;
}

.TodoItem_todo-item-box__1veC3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TodoItem_todo-item-box-label__357hP {
  text-transform: none;
  font-size: 0.875rem;
}

.TodoItem_todo-task__2JSse,
.TodoItem_todo-project__39dsS {
  word-break: break-all;
  overflow-wrap: break-word;
}

.TodoItem_todo-priority__2CLNK {
  font-size: 87.5%;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.TodoItem_todo-priority-box__3DxzC {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5em;
  text-align: right;
  border-radius: 0.25em;
  font-weight: 600;
  background-color: #f0f0f0;
}

.TodoItem_todo-item--completed__19luh .TodoItem_todo-priority-box__3DxzC {
  background-color: #fff;
}

.TodoItem_todo-priority-icon__1s36y {
  display: inline-block;
  vertical-align: middle;
  -webkit-margin-start: 1em;
          margin-inline-start: 1em;
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
}

.TodoItem_todo-priority-icon--low__1uCGJ {
  background-color: #f4c000;
}

.TodoItem_todo-priority-icon--normal__3V4pR {
  background-color: #41a452;
}

.TodoItem_todo-priority-icon--high__GJsHZ {
  background-color: #d5223e;
}

.TodoItem_todo-action__1-mkH {
  justify-content: center;
}

.TodoItem_todo-btn__3P1Gs {
  width: 100%;
}

.TodoItem_todo-btn-icon__lDT6y {
  color: inherit;
}

@media (min-width: 64em) {
  .TodoItem_todo-item-box__1veC3 {
    display: block;
  }

  .TodoItem_todo-item-box-label__357hP {
    display: none;
  }
}

.TodoList_todo-list__2CKe8 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  gap: 1em;
  font-size: 0.9375em;
}

.Todo_todo__122oA {
  overflow: hidden;
  min-height: 100vh;
  padding: 2em 1.5em 4em 1.5em;
}

@media (min-width: 64em) {
  .Todo_todo__122oA {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}


/* Reset */
[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}

/* Utility */
.is-hidden {
  display: none !important;
}

/* General */
body {
  margin: 0;
  padding: 0;
}

/* ====== Component ====== */

/* Default Settings */
.component {
  /* === Radius === */
  --component-radius: 4px;
  --component-radius-shadow: 1px;
  --component-radius-full: 999px;

  /* === Colors === */

  /* --- General --- */
  --component-bg-color: hsl(241, 5%, 96%);
  --component-content-bg-color: #fff;
  --component-brand-title-color: hsl(232, 52%, 5%);
  --component-title-color: hsl(232, 52%, 5%);
  --component-legend-color: hsl(232, 52%, 5%);
  --component-label-color: hsl(232, 52%, 5%);
  --component-legend-border-color: hsl(232, 5%, 92%);
  --component-text-color: hsl(232, 52%, 5%);
  --component-link-color: hsl(232, 100%, 50%);
  --component-link-color-hover-and-focus: hsl(232, 100%, 50%);
  --component-link-color-active: hsl(232, 100%, 50%);

  /* --- Nav --- */
  --component-nav-link-color: hsl(232, 2%, 5%);
  --component-nav-link-color-hover-and-focus: hsl(241, 2%, 10%);
  --component-nav-link-color-active: hsl(232, 2%, 10%);
  --component-nav-link-border-color: hsla(232, 2%, 90%, 0);
  --component-nav-link-border-color-hover-and-focus: hsla(232, 2%, 65%, 1);
  --component-nav-link-border-color-active: hsla(232, 2%, 10%, 1);
  --component-nav-link-bg-color: hsl(232, 5%, 90%);
  --component-nav-link-bg-color-hover-and-focus: hsl(232, 5%, 80%);
  --component-nav-link-bg-color-active: #fff;

  /* --- Form --- */
  --component-form-box-bg-color: #fff;
  --component-form-control-color: hsl(232, 2%, 5%);
  --component-form-control-bg-color: #fff;
  --component-form-control-bg-color-focus: #fff;
  --component-form-control-border-color: hsl(232, 2%, 50%);
  --component-form-control-bg-color-disabled: hsl(232, 2%, 90%);
  --component-form-control-color-disabled: hsl(232, 2%, 50%);
  --component-form-control-border-color-disabled: hsl(232, 2%, 85%);

  /* --- Button & Status --- */
  --component-btn-outline-focus-inner-color: #fff;

  /* Default */
  --component-default-color: hsl(232, 5%, 82%);
  --component-default-border-color: hsl(232, 5%, 82%);
  --component-default-bg-color: hsl(232, 5%, 82%);
  --component-default-bg-color-hover-and-focus: hsl(232, 5%, 80%);
  --component-default-bg-color-active: hsl(232, 5%, 77%);
  --component-default-text-color: hsl(232, 5%, 10%);
  --component-default-text-color-hover-and-focus: hsl(232, 5%, 10%);
  --component-default-text-color-active: hsl(232, 5%, 8%);
  --component-default-focus-outline-color: hsl(232, 5%, 90%);

  /* Primary */
  --component-primary-color: hsl(232, 76%, 30%);
  --component-primary-border-color: hsl(232, 76%, 30%);
  --component-primary-bg-color: hsl(232, 76%, 30%);
  --component-primary-bg-color-hover-and-focus: hsl(232, 76%, 33%);
  --component-primary-bg-color-active: hsl(232, 76%, 27%);
  --component-primary-text-color: rgba(255, 255, 255, 0.85);
  --component-primary-text-color-hover-and-focus: #fff;
  --component-primary-text-color-active: #fff;

  /* Warning */
  --component-warning-color: hsl(48, 86%, 52%);
  --component-warning-border-color: hsl(48, 86%, 52%);
  --component-warning-bg-color: hsl(48, 86%, 52%);
  --component-warning-bg-color-hover-and-focus: hsl(48, 86%, 56%);
  --component-warning-bg-color-active: hsl(48, 86%, 48%);
  --component-warning-text-color: rgba(0, 0, 0, 0.95);
  --component-warning-text-color-hover-and-focus: rgba(0, 0, 0, 0.95);
  --component-warning-text-color-active: rgba(0, 0, 0, 0.975);

  /* Dark */
  --component-dark-color: hsl(232, 22%, 23%);
  --component-dark-border-color: hsl(232, 22%, 23%);
  --component-dark-bg-color: hsl(232, 22%, 23%);
  --component-dark-bg-color-hover-and-focus: hsl(232, 22%, 25%);
  --component-dark-bg-color-active: hsl(232, 22%, 18%);
  --component-dark-text-color: rgba(255, 255, 255, 0.8);
  --component-dark-text-color-hover-and-focus: rgba(255, 255, 255, 0.9);
  --component-dark-text-color-active: rgba(255, 255, 255, 0.85);

  /* Success */
  --component-success-bg-color: hsl(150, 100%, 32%);
  --component-success-bg-color-hover-and-focus: hsl(150, 100%, 33.5%);
  --component-success-bg-color-active: hsl(150, 100%, 30%);
  --component-success-text-color: rgba(255, 255, 255, 0.975);
  --component-success-text-color-hover-and-focus: #fff;
  --component-success-text-color-active: #fff;

  /* Error */
  --component-error-color: hsl(350, 74%, 48%);
  --component-error-border-color: hsl(350, 74%, 48%);
  --component-error-bg-color: hsl(350, 74%, 48%);
  --component-error-bg-color-hover-and-focus: hsl(350, 74%, 51%);
  --component-error-bg-color-active: hsl(350, 74%, 46%);
  --component-error-text-color: #fff;
  --component-error-text-color-hover-and-focus: #fff;
  --component-error-text-color-active: #fff;
  --component-error-focus-outline-color: hsl(350, 74%, 80%);

  /* --- Checkbox & Radio & Toggle Switch --- */
  --component-chk-and-rdo-bg-color: hsl(232, 3%, 52%);
  --component-chk-and-rdo-border-color: hsl(232, 3%, 52%);
  --component-chk-and-rdo-color: hsl(232, 3%, 52%);
  --component-chk-and-rdo-color-focus-outline: hsl(232, 10%, 52%);
  --component-chk-and-rdo-color-checked: hsl(150, 100%, 32%);
  --component-chk-and-rdo-color-checked-focus-outline: hsl(150, 100%, 32%);
  --component-chk-and-rdo-icon-color: #fff;
  --component-chk-icon-color-inactive: rgba(255, 255, 255, 0.95);
  --component-chk-toggle-color: #fff;
  --component-chk-and-rdo-outline-focus-inner-color: #fff;

  /* --- Table --- */
  --component-table-border-color: hsl(232, 22%, 90%);
  --component-table-striped-bg-color: hsl(232, 22%, 96%);

  /* --- Pagination --- */
  --component-pagination-bg-color: hsl(232, 22%, 23%);
  --component-pagination-bg-color-hover-and-focus: hsl(232, 22%, 25%);
  --component-pagination-bg-color-active: hsl(232, 22%, 18%);
  --component-pagination-bg-color-disabled: hsl(232, 5%, 65%);
  --component-pagination-text-color: #fff;
  --component-pagination-text-color-hover-and-focus: #fff;
  --component-pagination-text-color-active: #fff;

  position: relative;
  font-size: 16px;
  line-height: 1.15;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: var(--component-bg-color);
  color: var(--component-text-color);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

/* Reset */
.component,
.component *,
.component *:before,
.component *:after {
  box-sizing: border-box;
}
.component a {
  color: var(--component-link-color);
  text-decoration: underline;
}
.component a:hover,
.component a:focus {
  color: var(--component-link-color-hover-and-focus);
  text-decoration: none;
}
.component a:active {
  color: var(--component-link-color-active);
  text-decoration: none;
}
.component fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
  border: 0;
}
.component legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  line-height: inherit;
}
.component legend + * {
  clear: left;
}
.component button,
.component input,
.component select,
.component textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
.component button,
.component input {
  overflow: visible;
}
.component button,
.component select {
  text-transform: none;
}
.component button,
.component [type="button"],
.component [type="submit"] {
  -webkit-appearance: button;
}
.component button::-moz-focus-inner,
.component [type="button"]::-moz-focus-inner,
.component [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.component button:-moz-focusring,
.component [type="button"]:-moz-focusring,
.component [type="submit"]:-moz-focusring {
  outline: 1px dotted currentColor;
}
.component textarea {
  overflow: auto;
  resize: vertical;
}
.component [type="checkbox"],
.component [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.component input[type="number"]::-webkit-inner-spin-button,
.component input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.component input[type="number"] {
  -moz-appearance: textfield;
}
.component img {
  border-style: none;
}

/* Utilities */
.component .component-is-hidden {
  display: none !important;
}
.component .component-is-disabled {
  opacity: 0.5;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.component .component-nowrap {
  white-space: nowrap;
}

.component .component-text-start {
  text-align: left;
}
.component .component-text-center {
  text-align: center;
}
.component .component-text-end {
  text-align: right;
}

.component .component-flex-h-start {
  display: flex;
  justify-content: flex-start;
}
.component .component-flex-h-center {
  display: flex;
  justify-content: center;
}
.component .component-flex-h-end {
  display: flex;
  justify-content: flex-end;
}
.component .component-flex-v-start {
  display: flex;
  align-items: flex-start;
}
.component .component-flex-v-center {
  display: flex;
  align-items: center;
}
.component .component-flex-v-end {
  display: flex;
  align-items: flex-end;
}

/* Layout */
.component [class*="component-grid-"] {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  grid-gap: 1.5em;
  grid-gap: 1.5em;
  gap: 1.5em;
}
.component-container {
  max-width: 72em;
  margin-left: auto;
  margin-right: auto;
}
.component .component-grid-set {
  grid-gap: 0.75em 1.5em;
  grid-gap: 0.75em 1.5em;
  gap: 0.75em 1.5em;
}
.component .component-grid-set--checkable {
  min-height: auto;
}
.component .component-form-set-block {
  position: relative;
}
.component .component-form-set-block:before {
  display: table;
  content: "";
}
.component .component-form-set-block:after {
  clear: both;
}

@media (min-width: 24em) {
  .component .component-grid-2-xxs {
    grid-template-columns: 1fr 1fr;
  }
  .component .component-grid-3-xxs {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .component .component-grid-4-xxs {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 32em) {
  .component .component-grid-2-xs {
    grid-template-columns: 1fr 1fr;
  }
  .component .component-grid-3-xs {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .component .component-grid-4-xs {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 48em) {
  .component .component-grid-2-sm {
    grid-template-columns: 1fr 1fr;
  }
  .component .component-grid-3-sm {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .component .component-grid-4-sm {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 64em) {
  .component .component-grid-2-md {
    grid-template-columns: 1fr 1fr;
  }
  .component .component-grid-3-md {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .component .component-grid-4-md {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 72em) {
  .component .component-grid-2-lg {
    grid-template-columns: 1fr 1fr;
  }
  .component .component-grid-3-lg {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .component .component-grid-4-lg {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 80em) {
  .component .component-grid-2-xl {
    grid-template-columns: 1fr 1fr;
  }
  .component .component-grid-3-xl {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .component .component-grid-4-xl {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 92em) {
  .component .component-grid-2-xxl {
    grid-template-columns: 1fr 1fr;
  }
  .component .component-grid-3-xxl {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .component .component-grid-4-xxl {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

/* Title */
.component .component-header {
  margin: 0;
  padding: 2em 1em;
  text-align: center;
}
.component .component-logo {
  margin-left: -1em;
  margin-right: -1em;
  width: 3em;
  height: 3em;
}
.component .component-brand-title {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  line-height: 1.15;
  font-weight: normal;
  color: var(--component-brand-title-color);
}

/* Tab */
.component .component-tab {
  display: grid;
  grid-template-columns: 18em 1fr;
}
.component .component-tab__nav-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-height: 100vh;
  background-color: var(--component-bg-color);
}
.component .component-tab__nav-list {
  margin: 0;
  padding: 0;
  text-align: left;
}
.component .component-tab__nav-btn {
  display: flex;
  align-items: center;
  padding: 1em;
  width: 100%;
  border: 0;
  cursor: pointer;
  background-color: var(--component-nav-link-bg-color);
  font-size: 0.9375em;
  font-weight: 500;
  color: var(--component-nav-link-color);
  text-decoration: none;
  border-left: 3px solid var(--component-nav-link-border-color);
  transition: background-color 0.2s ease, color 0.2s ease,
    border-left-color 0.2s ease;
}
.component .component-tab__nav-btn:hover,
.component .component-tab__nav-btn:focus {
  background-color: var(--component-nav-link-bg-color-hover-and-focus);
  color: var(--component-nav-link-color-hover-and-focus);
  border-left-color: var(--component-nav-link-border-color-hover-and-focus);
  text-decoration: none;
}
.component .component-tab__nav-btn:focus {
  outline: 0;
}
.component .component-tab__nav-btn:active {
  background-color: var(--component-nav-link-bg-color-active);
  color: var(--component-nav-link-color-active);
  text-decoration: none;
}
.component
  .component-tab__nav-item.component-is-active
  .component-tab__nav-btn {
  background-color: var(--component-nav-link-bg-color-active);
  pointer-events: none;
  border-left-color: var(--component-nav-link-border-color-active);
}
.component .component-tab__nav-btn-icon {
  width: 2em;
  height: 2em;
  fill: var(--component-nav-link-color);
  transition: fill 0.2s ease;
}
.component .component-tab__nav-btn:hover .component-tab__nav-btn-icon,
.component .component-tab__nav-btn:focus .component-tab__nav-btn-icon {
  fill: var(--component-nav-link-color-hover-and-focus);
}
.component .component-tab__nav-btn:active .component-tab__nav-btn-icon,
.component
  .component-tab__nav-item.component-is-active
  .component-tab__nav-btn-icon {
  fill: var(--component-nav-link-color-active);
}
.component .component-tab__nav-btn-text {
  padding-left: 1em;
}
.component .component-tab__content {
  min-width: 0;
  background-color: var(--component-content-bg-color);
}
.component .component-tab__content-item {
  display: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.component .component-tab__content-item.component-is-active {
  display: block;
}

/* Form */
.component .component-form {
  font-size: 1em;
  line-height: 1.15;
}
.component .component-form-title {
  display: flex;
  margin: 2em 0 0.75em 0;
  padding: 0;
  font-size: 1.5625em;
  font-weight: 600;
  color: var(--component-title-color);
}
.component .component-form-title__text {
  padding-bottom: 0.1em;
  border-bottom: 0.1925em solid var(--component-title-color);
}
.component .component-form-fieldset {
  padding: 2em 0;
  background-color: var(--component-form-box-bg-color);
}
.component .component-form-body {
  color: var(--component-text-color);
}
.component .component-form-row {
  margin-top: 1.5em;
}

@media (min-width: 48em) {
  .component .component-tab__content-item {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
  .component .component-form-title {
    font-size: 1.625em;
  }
}

.component .component-form-control-box--btn-group {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1em;
  grid-gap: 1em;
  gap: 1em;
}
.component .component-form-row--btn-set {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
  grid-gap: 1em;
  gap: 1em;
}
.component .component-form-row--btn-set .component-btn {
  width: 100%;
}

@media (min-width: 24em) {
  .component .component-form-row--btn-set-xxs .component-btn {
    width: auto;
  }
  .component .component-form-control-box--btn-group-xxs {
    grid-template-columns: auto -webkit-max-content;
    grid-template-columns: auto max-content;
  }
}

@media (min-width: 32em) {
  .component .component-form-row--btn-set-xs .component-btn {
    width: auto;
  }
  .component .component-form-control-box--btn-group-xs {
    grid-template-columns: auto -webkit-max-content;
    grid-template-columns: auto max-content;
  }
}

@media (min-width: 48em) {
  .component .component-form-row--btn-set-sm .component-btn {
    width: auto;
  }
  .component .component-form-control-box--btn-group-sm {
    grid-template-columns: auto -webkit-max-content;
    grid-template-columns: auto max-content;
  }
}

@media (min-width: 64em) {
  .component .component-form-row--btn-set-md .component-btn {
    width: auto;
  }
  .component .component-form-control-box--btn-group-md {
    grid-template-columns: auto -webkit-max-content;
    grid-template-columns: auto max-content;
  }
}

@media (min-width: 72em) {
  .component .component-form-row--btn-set-lg .component-btn {
    width: auto;
  }
  .component .component-form-control-box--btn-group-lg {
    grid-template-columns: auto -webkit-max-content;
    grid-template-columns: auto max-content;
  }
}

@media (min-width: 80em) {
  .component .component-form-row--btn-set-xl .component-btn {
    width: auto;
  }
  .component .component-form-control-box--btn-group-xl {
    grid-template-columns: auto -webkit-max-content;
    grid-template-columns: auto max-content;
  }
}

@media (min-width: 92em) {
  .component .component-form-row--btn-set-xxl .component-btn {
    width: auto;
  }
  .component .component-form-control-box--btn-group-xxl {
    grid-template-columns: auto -webkit-max-content;
    grid-template-columns: auto max-content;
  }
}

/* Buttons */
.component .component-btn-set {
  margin-top: 3.75em;
  text-align: center;
}
.component .component-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0.965em 1.5em;
  border-radius: var(--component-radius);
  height: auto;
  font: inherit;
  font-size: 0.875em;
  min-height: 3.215em;
  line-height: 1.15;
  background: var(--component-default-bg-color);
  color: var(--component-default-text-color);
  border: 1px solid transparent;
  transition: background-color 0.1s ease;
}
.component .component-btn:hover {
  cursor: pointer;
}
.component .component-btn:focus {
  outline: 0;
  box-shadow: none;
}
.component .component-btn:hover,
.component .component-btn:focus {
  background: var(--component-default-bg-color-hover-and-focus);
  color: var(--component-default-text-color-hover-and-focus);
}
.component .component-btn:focus-visible {
  box-shadow: 0 0 0 2px var(--component-btn-outline-focus-inner-color),
    0 0 0 4px var(--component-default-bg-color);
}
.component .component-btn:active {
  background-color: var(--component-default-bg-color-active);
  color: var(--component-default-text-color-active);
}

@media (min-width: 48em) {
  .component .component-btn {
    padding-top: 0.967em;
    padding-bottom: 0.967em;
    font-size: 0.9375em;
    min-height: 3.2em;
  }
}

.component .component-btn--primary {
  background-color: var(--component-primary-bg-color);
  color: var(--component-primary-text-color);
}
.component .component-btn--primary:hover,
.component .component-btn--primary:focus {
  background: var(--component-primary-bg-color-hover-and-focus);
  color: var(--component-primary-text-color-hover-and-focus);
}
.component .component-btn--primary:focus-visible {
  box-shadow: 0 0 0 2px var(--component-btn-outline-focus-inner-color),
    0 0 0 4px var(--component-primary-bg-color);
}
.component .component-btn--primary:active {
  background: var(--component-primary-bg-color-active);
  color: var(--component-primary-text-color-active);
}

.component .component-btn--warning {
  background-color: var(--component-warning-bg-color);
  color: var(--component-warning-text-color);
}
.component .component-btn--warning:hover,
.component .component-btn--warning:focus {
  background: var(--component-warning-bg-color-hover-and-focus);
  color: var(--component-warning-text-color-hover-and-focus);
}
.component .component-btn--warning:focus-visible {
  box-shadow: 0 0 0 2px var(--component-btn-outline-focus-inner-color),
    0 0 0 4px var(--component-warning-bg-color);
}
.component .component-btn--warning:active {
  background: var(--component-warning-bg-color-active);
  color: var(--component-warning-text-color-active);
}

.component .component-btn--dark {
  background-color: var(--component-dark-bg-color);
  color: var(--component-dark-text-color);
}
.component .component-btn--dark:hover,
.component .component-btn--dark:focus {
  background-color: var(--component-dark-bg-color-hover-and-focus);
  color: var(--component-dark-text-color-hover-and-focus);
}
.component .component-btn--dark:focus-visible {
  box-shadow: 0 0 0 2px var(--component-btn-outline-focus-inner-color),
    0 0 0 4px var(--component-dark-bg-color);
}
.component .component-btn--dark:active {
  background-color: var(--component-dark-bg-color-active);
  color: var(--component-dark-text-color-active);
}

.component .component-btn--success {
  background-color: var(--component-success-bg-color);
  color: var(--component-success-text-color);
}
.component .component-btn--success:hover,
.component .component-btn--success:focus {
  background-color: var(--component-success-bg-color-hover-and-focus);
  color: var(--component-success-text-color-hover-and-focus);
}
.component .component-btn--success:focus-visible {
  box-shadow: 0 0 0 2px var(--component-btn-outline-focus-inner-color),
    0 0 0 4px var(--component-success-bg-color);
}
.component .component-btn--success:active {
  background-color: var(--component-success-bg-color-active);
  color: var(--component-success-text-color-active);
}

.component .component-btn--error {
  background-color: var(--component-error-bg-color);
  color: var(--component-error-text-color);
}
.component .component-btn--error:hover,
.component .component-btn--error:focus {
  background-color: var(--component-error-bg-color-hover-and-focus);
  color: var(--component-error-text-color-hover-and-focus);
}
.component .component-btn--error:focus-visible {
  box-shadow: 0 0 0 2px var(--component-btn-outline-focus-inner-color),
    0 0 0 4px var(--component-error-bg-color);
}
.component .component-btn--error:active {
  background-color: var(--component-error-bg-color-active);
  color: var(--component-error-text-color-active);
}

/* Button Icons */
.component .component-btn__icon {
  display: block;
  margin-top: -1em;
  margin-bottom: -1em;
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
}
.component .component-btn--has-icon-start .component-btn__icon {
  margin-right: 0.25em;
}
.component .component-btn--has-icon-end .component-btn__icon {
  order: 1;
  margin-left: 0.25em;
}
.component .component-btn__text {
  display: block;
}
.component .component-btn--has-icon-only .component-btn__text {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.component [class*="component-btn--show-icon"] .component-btn__icon {
  display: none;
}

@media (min-width: 24em) {
  .component .component-btn--show-icon-xxs .component-btn__icon {
    display: inherit;
  }
}

@media (min-width: 32em) {
  .component .component-btn--show-icon-xs .component-btn__icon {
    display: inherit;
  }
}

@media (min-width: 48em) {
  .component .component-btn--show-icon-sm .component-btn__icon {
    display: inherit;
  }
}

@media (min-width: 64em) {
  .component .component-btn--show-icon-md .component-btn__icon {
    display: inherit;
  }
}

@media (min-width: 72em) {
  .component .component-btn--show-icon-lg .component-btn__icon {
    display: inherit;
  }
}

@media (min-width: 80em) {
  .component .component-btn--show-icon-xl .component-btn__icon {
    display: inherit;
  }
}

@media (min-width: 92em) {
  .component .component-btn--show-icon-xxl .component-btn__icon {
    display: inherit;
  }
}

/* Form Legend */
.component .component-form-legend {
  margin-top: 0;
  margin-bottom: 1.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--component-legend-border-color);
  color: var(--component-legend-color);
  font-size: 1.1875em;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  word-break: break-word;
  overflow-wrap: break-word;
}

@media (min-width: 48em) {
  .component .component-form-legend {
    font-size: 1.3125em;
  }
}

/* Form Label */
.component .component-form-label {
  display: block;
  margin-bottom: 0.375em;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 0.6875em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: var(--component-label-color);
}

@media (min-width: 48em) {
  .component .component-form-label {
    font-size: 0.75em;
  }
}

/* Form Control */
.component .component-form-control-box {
  position: relative;
}
.component .component-form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.6875em 0.75em;
  font-size: 0.9375em;
  font-weight: 400;
  line-height: 1.5;
  color: var(--component-form-control-color);
  background: var(--component-form-control-bg-color);
  background-clip: padding-box;
  border: 1px solid var(--component-default-border-color);
  border-radius: var(--component-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.component .component-form-control:not(.component-form-control--textarea) {
  height: 3em !important;
}
.component .component-form-control--select {
  padding-right: 2.5em;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.component .component-form-control-select-icon {
  position: absolute;
  top: 50%;
  right: 0.875em;
  margin-top: -0.625em;
  width: 1.25em;
  height: 1.25em;
  fill: var(--component-default-color);
  pointer-events: none;
}
.component .component-form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.component .component-form-control:focus {
  color: var(--component-form-control-color);
  background: var(--component-form-control-bg-color-focus);
  border-color: var(--component-default-border-color);
  outline: 0;
  box-shadow: 0 0 0 3px var(--component-default-focus-outline-color);
}
.component .component-error .component-form-control:focus {
  box-shadow: 0 0 0 3px var(--component-error-focus-outline-color);
}
.component .component-form-control:disabled,
.component .component-form-control[readonly] {
  background: var(--component-form-control-bg-color-disabled);
  color: var(--component-form-control-color-disabled);
  border-color: var(--component-form-control-border-color-disabled);
  opacity: 1;
}
.component .component-form-control--select {
  max-width: none;
}
.component .component-form-control--textarea {
  height: 12em;
  min-height: 6em;
}

@media (min-width: 48em) {
  .component .component-form-control {
    font-size: 1em;
  }
}

/* Toggle Switch & Checkbox & Radio */
.component .component-toggle-switch-box,
.component .component-chk-box,
.component .component-rdo-box {
  float: left;
  display: flex;
  align-items: center;
  min-height: 2.8125em;
}
.component .component-grid-set--checkable {
  padding-top: 0.5em;
}
.component .component-grid-set--checkable .component-toggle-switch-box,
.component .component-grid-set--checkable .component-chk-box,
.component .component-grid-set--checkable .component-rdo-box {
  min-height: auto;
}
.component .component-toggle-switch,
.component .component-chk,
.component .component-rdo {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: 0.5em;
  margin-left: auto;
  margin-right: auto;
  font: inherit;
  font-weight: 500;
  line-height: 1.15;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.component .component-toggle-switch__form-control,
.component .component-chk__form-control,
.component .component-rdo__form-control {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.component .component-toggle-switch__bar:hover,
.component-chk__icon-box:hover,
.component
  .component-rdo__form-control:not(:checked)
  + .component-rdo__icon-box:hover {
  cursor: pointer;
}
.component .component-toggle-switch__text,
.component .component-chk__text,
.component .component-rdo__text {
  padding-left: 0.5em;
  font-weight: normal;
  font-size: 0.9375em;
}

@media (min-width: 48em) {
  .component .component-toggle-switch-box,
  .component .component-chk-box,
  .component .component-rdo-box {
    min-height: 3em;
  }
  .component .component-toggle-switch__text,
  .component .component-chk__text,
  .component .component-rdo__text {
    font-size: 1em;
  }
}

/* Toggle Switch */
.component .component-toggle-switch__bar {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 5em;
  height: 2.5em;
  border-radius: var(--component-radius-full);
  transition: box-shadow 0.15s ease;
}
.component .component-toggle-switch__bar:before,
.component .component-toggle-switch__bar:after {
  position: absolute;
  z-index: 0;
  content: "";
  top: 0;
  left: 0;
}
.component .component-toggle-switch__bar:before {
  width: 100%;
  height: 100%;
  border-radius: var(--component-radius-full);
  background-color: var(--component-chk-and-rdo-color);
  background-clip: content-box;
  transition: background-color 0.3s ease, left 0.2s ease;
}
.component .component-toggle-switch__bar:after {
  width: 2em;
  height: 2em;
  margin: 0.25em;
  border-radius: var(--component-radius-full);
  background-color: var(--component-chk-toggle-color);
  transition: left 0.15s ease;
}
.component
  .component-toggle-switch__form-control:checked
  + .component-toggle-switch__bar:before {
  background-color: var(--component-chk-and-rdo-color-checked);
}
.component
  .component-toggle-switch__form-control:checked
  + .component-toggle-switch__bar:after {
  left: 2.5em;
}
.component .component-toggle-switch__on,
.component .component-toggle-switch__off {
  position: absolute;
  z-index: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  transition: opacity 0.15s;
}
.component .component-toggle-switch__on {
  top: 0.625em;
  left: 0.75em;
  opacity: 0;
}
.component .component-toggle-switch__off {
  top: 0.625em;
  right: 0.75em;
  opacity: 1;
}
.component .component-toggle-switch__on-icon,
.component .component-toggle-switch__off-icon {
  display: inline-block;
  vertical-align: middle;
}
.component .component-toggle-switch__on-icon {
  width: 1.3125em;
  height: 1.3125em;
  fill: var(--component-chk-and-rdo-icon-color);
}
.component .component-toggle-switch__off-icon {
  width: 1.25em;
  height: 1.25em;
  fill: var(--component-chk-icon-color-inactive);
}
.component
  .component-toggle-switch__form-control:checked
  + .component-toggle-switch__bar
  .component-toggle-switch__on {
  opacity: 1;
}
.component
  .component-toggle-switch__form-control:checked
  + .component-toggle-switch__bar
  .component-toggle-switch__off {
  opacity: 0;
}
.component
  .component-toggle-switch__form-control:focus
  + .component-toggle-switch__bar {
  box-shadow: 0 0 0 2px var(--component-chk-and-rdo-outline-focus-inner-color),
    0 0 0 4px var(--component-chk-and-rdo-color-focus-outline);
}
.component
  .component-toggle-switch__form-control:checked:focus
  + .component-toggle-switch__bar {
  box-shadow: 0 0 0 2px var(--component-chk-and-rdo-outline-focus-inner-color),
    0 0 0 4px var(--component-chk-and-rdo-color-checked-focus-outline);
}

/* Checkbox & Radio */
.component .component-chk__form-label,
.component .component-rdo__form-label {
  display: inline-block;
  vertical-align: baseline;
}
.component .component-chk__icon-box,
.component .component-rdo__icon-box {
  position: relative;
  flex: 0 0 auto;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  vertical-align: middle;
  background-color: var(--component-chk-and-rdo-bg-color);
  border: 0.25em solid var(--component-chk-and-rdo-border-color);
  transition: background-color 0.15s ease, border-color 0.15s ease,
    box-shadow 0.15s ease;
}
.component .component-chk__icon-box {
  border-radius: var(--component-radius);
}
.component .component-rdo__icon-box {
  border-radius: var(--component-radius-full);
}
.component .component-chk__form-control:checked + .component-chk__icon-box,
.component .component-rdo__form-control:checked + .component-rdo__icon-box {
  background-color: var(--component-chk-and-rdo-color-checked);
  border-color: var(--component-chk-and-rdo-color-checked);
}
.component .component-chk__form-control:focus + .component-chk__icon-box,
.component .component-rdo__form-control:focus + .component-rdo__icon-box {
  box-shadow: 0 0 0 2px var(--component-chk-and-rdo-outline-focus-inner-color),
    0 0 0 4px var(--component-chk-and-rdo-color-focus-outline);
}
.component
  .component-chk__form-control:checked:focus
  + .component-chk__icon-box,
.component
  .component-rdo__form-control:checked:focus
  + .component-rdo__icon-box {
  box-shadow: 0 0 0 2px var(--component-chk-and-rdo-outline-focus-inner-color),
    0 0 0 4px var(--component-chk-and-rdo-color-checked-focus-outline);
}
.component .component-chk__icon,
.component .component-rdo__icon {
  opacity: 0;
  position: absolute;
  width: 1.25em;
  height: 1.25em;
  top: -0.125em;
  right: -0.125em;
  bottom: -0.125em;
  left: -0.125em;
  fill: var(--component-chk-and-rdo-icon-color);
  transition: opacity 0.15s ease;
}
.component
  .component-chk__form-control:checked
  + .component-chk__icon-box
  .component-chk__icon,
.component
  .component-rdo__form-control:checked
  + .component-rdo__icon-box
  .component-rdo__icon {
  opacity: 1;
}

/* Table */
.component .component-table-box {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0.875em;
}
.component .component-table {
  width: 100%;
  border-collapse: collapse;
  border: 0;
  vertical-align: top;
}
.component .component-table thead th {
  padding: 0.75em 1em;
  text-align: left;
  font-weight: 600;
  line-height: 1.2;
  border-bottom: 2px solid var(--component-table-border-color);
  color: var(--component-text-color);
}
.component .component-table tbody th {
  padding: 0.75em 1em;
  text-align: left;
  font-weight: 600;
  line-height: 1.2;
  border-bottom: 1px solid var(--component-table-border-color);
  color: var(--component-text-color);
}
.component .component-table tbody td {
  padding: 0.75em 1em;
  text-align: left;
  font-weight: 400;
  line-height: 1.2;
  border-bottom: 1px solid var(--component-table-border-color);
  color: var(--component-text-color);
}
.component .component-table--striped tbody tr:nth-child(2n + 1) th,
.component .component-table--striped tbody tr:nth-child(2n + 1) td {
  background-color: var(--component-table-striped-bg-color);
}

@media (min-width: 48em) {
  .component .component-table-box {
    font-size: 1em;
  }
  .component .component-table thead th,
  .component .component-table tbody th .component .component-table tbody td {
    padding: 1em;
  }
}

/* Info Box */
.component .component-info-box {
  position: relative;
  display: inline-grid;
  grid-template-columns: auto;
  grid-gap: 0.71428571428em;
  grid-gap: 0.71428571428em;
  gap: 0.71428571428em;
  padding: 0.75em 1em;
  background-color: var(--component-default-bg-color);
  color: var(--component-default-text-color);
  text-align: left;
  font-size: 0.875em;
  line-height: 1.375;
  border-radius: var(--component-radius);
  word-break: break-word;
  overflow-wrap: break-word;
}
.component .component-info-box--full-width {
  display: grid;
}
.component .component-info-box--iconed {
  grid-template-columns: 1.25em auto;
}
.component .component-info-box--closable {
  grid-template-columns: auto 1.5em;
}
.component .component-info-box--iconed.component-info-box--closable {
  grid-template-columns: 1.25em auto 1.5em;
}
.component .component-info-box__btn-close {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  min-height: 0;
  padding: 0;
  background: none;
  border: 0;
  color: currentColor;
  line-height: 1;
  border-radius: var(--component-radius-full);
}
.component .component-info-box__btn-close:hover,
.component .component-info-box__btn-close:focus {
  background: none;
  border: 0;
  color: currentColor;
}
.component .component-info-box__btn-close:focus-visible {
  background: none;
  border: 0;
  color: currentColor;
  box-shadow: 0 0 0 2px currentColor;
}
.component .component-info-box__btn-close:active {
  border: 0;
  color: currentColor;
}

.component .component-info-box--primary {
  background-color: var(--component-primary-bg-color);
  color: var(--component-primary-text-color);
}
.component .component-info-box--warning {
  background-color: var(--component-warning-bg-color);
  color: var(--component-warning-text-color);
}
.component .component-info-box--dark {
  background-color: var(--component-dark-bg-color);
  color: var(--component-dark-text-color);
}
.component .component-info-box--success {
  background-color: var(--component-success-bg-color);
  color: var(--component-success-text-color);
}
.component .component-info-box--error {
  background-color: var(--component-error-bg-color);
  color: var(--component-error-text-color);
}

.component .component-info-box--top-left:before {
  position: absolute;
  content: "";
  top: -0.5em;
  left: 1em;
  border-width: 0 0.5em 0.5em 0.5em;
  border-style: solid;
  border-color: transparent transparent var(--component-default-color)
    transparent;
}
.component .component-info-box--bottom-left:before {
  position: absolute;
  content: "";
  border-style: solid;
  left: 1em;
  bottom: -0.5em;
  border-width: 0.5em 0.5em 0 0.5em;
  border-color: var(--component-default-color) transparent transparent
    transparent;
}

.component .component-info-box--top-left.component-info-box--primary:before {
  border-bottom-color: var(--component-primary-bg-color);
}
.component .component-info-box--top-left.component-info-box--warning:before {
  border-bottom-color: var(--component-warning-bg-color);
}
.component .component-info-box--top-left.component-info-box--dark:before {
  border-bottom-color: var(--component-dark-bg-color);
}
.component .component-info-box--top-left.component-info-box--error:before {
  border-bottom-color: var(--component-error-bg-color);
}
.component .component-info-box--top-left.component-info-box--success:before {
  border-bottom-color: var(--component-success-bg-color);
}

.component .component-info-box--bottom-left.component-info-box--primary:before {
  border-top-color: var(--component-primary-bg-color);
}
.component .component-info-box--bottom-left.component-info-box--warning:before {
  border-top-color: var(--component-warning-bg-color);
}
.component .component-info-box--bottom-left.component-info-box--dark:before {
  border-top-color: var(--component-dark-bg-color);
}
.component .component-info-box--bottom-left.component-info-box--error:before {
  border-top-color: var(--component-error-bg-color);
}
.component .component-info-box--bottom-left.component-info-box--success:before {
  border-top-color: var(--component-success-bg-color);
}

.component-info-box__icon {
  margin-top: 0.05em;
  width: 1.25em;
  height: 1.25em;
  fill: currentColor;
}
.component-info-box__title {
  margin: 0.125em 0 0 0;
  padding: 0;
  font-size: 0.875em;
  line-height: 1.375;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.component-info-box__btn-close-icon {
  margin-top: 0.05em;
  width: 1.25em;
  height: 1.25em;
  fill: currentColor;
}

/* Error – Form Control, Toggle Switch, Radio, Checkbox & Response Message */
.component-response-message {
  clear: both;
  margin-top: 0.5em;
  font-size: 0.75em;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.5;
}

.component .component-error .component-form-control {
  border-color: var(--component-error-border-color);
  color: var(--component-error-color);
}
.component .component-error .component-form-label,
.component .component-error .component-response-message {
  color: var(--component-error-color);
}
.component .component-error .component-form-control-select-icon {
  fill: var(--component-error-color);
}

.component
  .component-error
  .component-toggle-switch__form-control
  + .component-toggle-switch__bar:before,
.component
  .component-error
  .component-toggle-switch__form-control:checked
  + .component-toggle-switch__bar:before {
  background-color: var(--component-error-color);
}
.component
  .component-error
  .component-toggle-switch__form-control:focus
  + .component-toggle-switch__bar,
.component
  .component-error
  .component-toggle-switch__form-control:checked:focus
  + .component-toggle-switch__bar {
  box-shadow: 0 0 0 2px var(--component-chk-and-rdo-outline-focus-inner-color),
    0 0 0 4px var(--component-error-color);
}
.component .component-error .component-toggle-switch__text {
  color: var(--component-error-color);
}

.component .component-error .component-chk__icon-box,
.component .component-error .component-rdo__icon-box {
  background-color: var(--component-error-color);
  border-color: var(--component-error-color);
}
.component .component-error .component-chk__text,
.component .component-error .component-rdo__text {
  color: var(--component-error-color);
}
.component
  .component-error
  .component-chk__form-control:focus
  + .component-chk__icon-box,
.component
  .component-error
  .component-rdo__form-control:focus
  + .component-rdo__icon-box {
  box-shadow: 0 0 0 2px var(--component-chk-and-rdo-outline-focus-inner-color),
    0 0 0 4px var(--component-error-color);
}
.component
  .component-error
  .component-chk__form-control:checked
  + .component-chk__icon-box,
.component
  .component-error
  .component-rdo__form-control:checked
  + .component-rdo__icon-box {
  background-color: var(--component-error-color);
  border-color: var(--component-error-color);
}
.component
  .component-error
  .component-chk__form-control:checked:focus
  + .component-chk__icon-box,
.component
  .component-error
  .component-rdo__form-control:checked:focus
  + .component-rdo__icon-box {
  background-color: var(--component-error-color);
  border-color: var(--component-error-color);
}

/* Pagination */
.component .component-pagination {
  display: flex;
}
.component .component-pagination--top {
  margin-bottom: 2em;
}
.component .component-pagination--bottom {
  margin-top: 2em;
}
.component .component-pagination--left {
  justify-content: flex-start;
}
.component .component-pagination--right {
  justify-content: flex-end;
}
.component .component-pagination--space-between {
  justify-content: space-between;
}
.component .component-pagination__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -0.5em 0 0 0;
  padding: 0;
  list-style: none;
}
.component .component-pagination__item {
  margin-top: 0.5em;
}
.component .component-pagination__item:not(:last-child) {
  margin-right: 0.5em;
}
.component .component-pagination__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.75em;
  padding-right: 0.75em;
  min-width: 2.5em;
  width: auto;
  height: 2.5em;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0.25em;
  font-size: 0.75em;
  font-weight: 500;
  background: var(--component-pagination-bg-color);
  color: var(--component-pagination-text-color);
}
.component .component-pagination__link:hover,
.component .component-pagination__link:focus {
  background: var(--component-pagination-bg-color-hover-and-focus);
  color: var(--component-pagination-text-color-hover-and-focus);
}
.component .component-pagination__item--is-active .component-pagination__link,
.component .component-pagination__link:active {
  background-color: var(--component-pagination-bg-color-active);
  color: var(--component-pagination-text-color-active);
}
.component .component-pagination__link--is-disabled {
  pointer-events: none;
  background: var(--component-pagination-bg-color-disabled);
  color: var(--component-pagination-text-color);
}
.component .component-pagination__link-icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
}
.component .component-pagination__link-icon--prev {
  fill: var(--component-pagination-text-color);
}
.component .component-pagination__link-icon--next {
  fill: var(--component-pagination-text-color);
}
.component .component-pagination .component-pagination__form-control {
  padding: 0.25em 0.5em;
  height: 2em !important;
  border-width: 3px;
  border-color: var(--component-pagination-bg-color);
  text-align: center;
}
.component
  .component-pagination
  .component-pagination__form-control--maxlength-1 {
  width: 2.1875em;
}
.component
  .component-pagination
  .component-pagination__form-control--maxlength-2 {
  width: 2.66666666667em;
}
.component
  .component-pagination
  .component-pagination__form-control--maxlength-3 {
  width: 3.25em;
}
.component .component-pagination .component-pagination__form-control:focus {
  border-color: var(--component-pagination-bg-color-hover-and-focus);
}

@media (min-width: 48em) {
  .component .component-pagination__link {
    font-size: 0.875em;
  }
  .component .component-pagination .component-pagination__form-control {
    height: 2.1875em !important;
  }
}

