.todo-form {
  margin-bottom: 2em;
}

.todo-form-row {
  display: grid;
  align-items: end;
  grid-template-columns: 1fr;
  gap: 1em;
}

.todo-btn {
  width: 100%;
}

@media (min-width: 64em) {
  .todo-form-row {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }
}
