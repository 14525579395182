.todo-item {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  background-color: #fff;
  padding: 0.5em 0.5em 0.5em 1em;
  gap: 2em;
  border-radius: 0.25em;
}

.todo-item--header {
  display: none;
  background-color: #dadada;
}

@media (min-width: 64em) {
  .todo-item,
  .todo-item--header {
    display: grid;
    grid-template-columns: 6em 1fr 20em 7em 5em;
  }
}
