.todo {
  overflow: hidden;
  min-height: 100vh;
  padding: 2em 1.5em 4em 1.5em;
}

@media (min-width: 64em) {
  .todo {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}
